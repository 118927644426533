import { Outlet } from "react-router-dom";

import Sidebar from "./partials/sidebar";

const Layout = () => {
    return (
        <div className="admin-dashboard-layout">
            <Sidebar />
            <div className="admin-dashboard-content">
                <Outlet />
            </div>
        </div>
    )
};

export default Layout;