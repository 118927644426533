import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

import { API_URL } from "../../../config";

const ItemContentValue = ({ values }) => {
    return (
        <div className="item-values-container">
            {values.map(({ title, value }, index) =>  {
                return (
                    <div className="item-value-container" key={`item-value-${title}-${index}`}>
                        <div className="item-value-title">{title}</div>
                        <div className="item-value-text">{value !== undefined ? value : "-"}</div>
                    </div>
                )
            })}
        </div>
    );
};

const OrdersTable = ({ orders }) => {
    const navigate = useNavigate();

    return (
        <>
            <table className="userorders-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => (
                        <tr>
                            <td>{order.title}</td>
                            <td>${order.total.toFixed(2)}</td>
                            <td><div className={`userorders-table-status userorders-table-status-${order.status.toLowerCase()}`}>{order.status.replaceAll("_", " ")}</div></td>
                            <td>{new Date(order.created_at).toLocaleString()}</td>
                            <td><button className="btn btn-snb" onClick={() => navigate(`/dashboard/orders/${order.order_id}`)}>View</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
};

const User = () => {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [balance, setBalance] = useState(0);
    const [orders, setOrders] = useState([]);
    const [lastLoginIp, setLastLoginIp] = useState("");
    const [registerIp, setRegisterIp] = useState("");
    const [lastLoginAt, setLastLoginAt] = useState(null);
    const [createdAt, setCreatedAt] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const getUserId = () => {
        let oId = window.location.pathname.split("/")[3];
        return oId.includes("/") ? oId.split("/")[0] : oId;
    };

    const setUserBalance = (balance) => {
        if(isNaN(balance)) return;
        if(!(parseInt(balance) < 999999)) return;
        if(!(parseInt(balance) > -0.1)) return;
        setBalance(parseInt(balance));
    };

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/users/${getUserId()}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };

            setUsername(data.user.username);
            setEmail(data.user.email);
            setBalance(data.user.balance);
            setOrders(data.user.orders);
            setLastLoginIp(data.user.last_login_ip);
            setRegisterIp(data.user.register_ip);
            setLastLoginAt(data.user.last_login_at);
            setCreatedAt(data.user.created_at);

            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    const saveUser = async() => {
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/users/${getUserId()}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username,
                    email,
                    balance: parseFloat(balance)
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };

            fetchData();
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">User {username}</div>
                    <div className="user-home-welcome-text">View and manage user {username} ({getUserId()})</div>
                </div>

                <div className="item-content-container">
                    <div className="item-content-row">
                        <ItemContentValue 
                            values={
                                [
                                    {
                                        title: "Username",
                                        value: <input type="text" className="input" defaultValue={username} onChange={(e) => setUsername(e.target.value)} />
                                    },
                                    {
                                        title: "Last Login IP",
                                        value: lastLoginIp
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Email",
                                        value: <input type="text" className="input" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
                                    },
                                    {
                                        title: "Register IP",
                                        value: registerIp
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Balance",
                                        value: <input type="number" className="input" min={0} max={999999} defaultValue={balance} onChange={(e) => setUserBalance(e.target.value)} />
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Last Login At",
                                        value: new Date(lastLoginAt).toLocaleString()
                                    }
                                ]
                            }
                        />
                        <ItemContentValue
                            values={
                                [
                                    {
                                        title: "Created At",
                                        value: new Date(createdAt).toLocaleString()
                                    }
                                ]
                            }
                        />
                    </div>
                    <div className="user-info-bottom">
                        <button className="btn btn-snb" onClick={() => saveUser()}>Save</button>
                    </div>
                </div>

                <div className="item-title-margin">
                    <div className="userorder-payment-status-update-title">{username}'s orders</div>
                    <OrdersTable orders={orders} />
                </div>
            </div>
        </div>
    );
};

export default User;