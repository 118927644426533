import { useContext, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Cookies from "js-cookie";

import "./scss/app.scss";

import UserContext from "./contexts/user";

import AuthLayout from "./views/auth/layout";
import AuthLogin from "./views/auth/pages/login";
import AuthLogout from "./views/auth/pages/logout";

import DashboardLayout from "./views/dashboard/layout";
import DashboardIndex from "./views/dashboard/pages/index";
import DashboardProductCategories from "./views/dashboard/pages/productcategories";
import DashboardProductCategory from "./views/dashboard/pages/productcategory";
import DashboardProduct from "./views/dashboard/pages/product";
import DashboardReports from "./views/dashboard/pages/reports";
import DashboardTags from "./views/dashboard/pages/tags";
import DashboardTag from "./views/dashboard/pages/tag";
import DashboardOrders from "./views/dashboard/pages/orders";
import DashboardOrder from "./views/dashboard/pages/order";
import DashboardAnnouncements from "./views/dashboard/pages/announcements";
import DashboardTickets from "./views/dashboard/pages/tickets";
import DashboardTicket from "./views/dashboard/pages/ticket";
import DashboardUsers from "./views/dashboard/pages/users";
import DashboardUser from "./views/dashboard/pages/user";
import DashboardPaymentMethods from "./views/dashboard/pages/paymentmethods";
import DashboardAdminUsers from "./views/dashboard/pages/adminusers";
import DashboardAdminUser from "./views/dashboard/pages/adminuser";

import { API_URL } from "./config";

const App = () => {
	const [user, setUser] = useState({ loggedin: false, username: null });

	useEffect(() => {
		const fetchData = async() => {
			try {
				const data = await fetch(`${API_URL}/admin/session/me`, {
					method: "GET",
					credentials: "include"
				}).then(r => r.json());
				if(!data.success) {
					Cookies.remove("admin_loggedin");
					Cookies.remove("admin_username");
					Cookies.remove("admin_usertype")
					setUser({ loggedin: false, username: null });
					return;
				};
				setUser({
					loggedin: true,
					username: data.user.username,
					user_type: data.user.user_type
				});
				Cookies.set("admin_loggedin", "true");
				Cookies.set("admin_username", data.user.username);
				Cookies.set("admin_usertype", data.user.user_type);
			} catch(e) {
				console.log(e);
				setUser({ loggedin: false, username: null });
			}
		};
		fetchData();
		if(Cookies.get("admin_loggedin") === "true") {
			setUser({
				loggedin: true,
				username: Cookies.get("admin_username"),
				user_type: Cookies.get("admin_usertype"),
			});
		};
	}, []);

	return (
		<BrowserRouter>
			<UserContext.Provider value={{ user, setUser }}>
				<Routes>
					<Route path="/auth" element={<AuthLayout />}>
						<Route path="login" element={<AuthLogin />} />
						<Route path="logout" element={<AuthLogout />} />
					</Route>
					<Route path="/dashboard" element={<DashboardLayout />}>
						<Route path="home" element={<DashboardIndex />} />
						<Route path="products">
							<Route path="categories" element={<DashboardProductCategories />} />
							<Route path="categories/:category_id" element={<DashboardProductCategory />} />
							<Route path="categories/:category_id/:product_id" element={<DashboardProduct />} />
						</Route>
						<Route path="reports">
							<Route index element={<DashboardReports />} />
						</Route>
						<Route path="tags">
							<Route index element={<DashboardTags />} />
							<Route path=":tag_id" element={<DashboardTag />} />
						</Route>
						<Route path="orders">
							<Route index element={<DashboardOrders />} />
							<Route path=":order_id" element={<DashboardOrder />} />
						</Route>
						<Route path="announcements">
							<Route index element={<DashboardAnnouncements />} />
						</Route>
						<Route path="tickets">
							<Route index element={<DashboardTickets />} />
							<Route path=":tikcet_id" element={<DashboardTicket />} />
						</Route>
						<Route path="users">
							<Route index element={<DashboardUsers />} />
							<Route path=":user_id" element={<DashboardUser />} />
						</Route>
						<Route path="paymentmethods" element={<DashboardPaymentMethods />} />
						<Route path="adminusers">
							<Route index element={<DashboardAdminUsers />} />
							<Route path=":user_id" element={<DashboardAdminUser />} />
						</Route>
					</Route>
				</Routes>
			</UserContext.Provider>
		</BrowserRouter>
	)
};

export default App;