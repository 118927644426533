import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const Error = ({ error, alert }) => {
    return (
        <div className="storefront-error">
            <div className="storefront-error-icon"><FontAwesomeIcon icon={alert === true ? faInfoCircle : faCircleExclamation} /></div>
            <div className="storefront-error-title">An error occurred!</div>
            <div className="storefront-error-text">{error}</div>
        </div>
    )
};

export default Error;