import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const ProductCategoriesTable = ({ product_categories }) => {
    const navigate = useNavigate();

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Products</th>
                    <th>Visible</th>
                    <th>Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {product_categories.map((product_category, index) => (
                    <tr>
                        <td>{product_category.name}</td>
                        <td>{product_category.products}</td>
                        <td className="capitalize">{product_category.visible.toString()}</td>
                        <td>{new Date(product_category.created_at).toLocaleString()}</td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/products/categories/${product_category.category_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const EditTag = ({ tag_id, setRefresh, tagName, setTagName, tagColor, setTagColor }) => {
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/tags/${tag_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: tagName,
                    color_hex: tagColor
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
            };
            setRefresh(Math.random());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        }
    };

    const submitDelete = async () => {
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/tags/${tag_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
            };
            setLoaded(true);
            navigate("/dashboard/tags");
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    if(loaded === false) return (
        <div className="item-create-box mt-2">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box mt-2">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Edit product category</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Name</label>
                <input type="text" className="input" placeholder="My Tag Name" defaultValue={tagName} onChange={(e) => setTagName(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Color</label>
                <input type="color" className="input" style={{ height: "60px" }} defaultValue={tagColor} onChange={(e) => setTagColor(e.target.value)} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Edit tag</button>
            <button className="btn btn-dng" onClick={() => submitDelete()}>Delete tag</button>
        </div>
    )
};

const Tag = () => {
    const [tagName, setTagName] = useState("");
    const [tagColor, setTagColor] = useState("");
    const [productCategories, setProductCategories] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const getTagId = () => {
        try {
            let cId = window.location.pathname.split("/")[3];
            if(cId.includes("/")) return cId.split("/")[0];
            return cId;
        } catch(e) {
            console.log(e);
            return null;
        }
    };

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/tags/${getTagId()}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setTagName(data.tag.name);
            setTagColor(data.tag.color_hex);
            setProductCategories(data.tag.product_categories);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log(refresh);
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">{tagName}</div>
                    <div className="user-home-welcome-text">View and manage the {tagName} tag</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <ProductCategoriesTable tag_id={getTagId()} product_categories={productCategories} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <EditTag
                            tag_id={getTagId()}
                            setRefresh={() => setRefresh()}
                            tagName={tagName}
                            setTagName={setTagName}
                            tagColor={tagColor}
                            setTagColor={setTagColor}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Tag;