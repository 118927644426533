import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faShoppingCart, faScrewdriverWrench, faWallet, faUser } from "@fortawesome/free-solid-svg-icons";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

import { API_URL } from "../../../config";

const TicketSendMessage = ({ messageContent, setMessageContent, sendTicketMessage, markReplacement, markSolved, userId }) => {
    const navigate = useNavigate();

    return (
        <div className="userticket-send-message">
            <div className="userticket-message-title">Send a new message to the ticket</div>
            <textarea rows={5} defaultValue={messageContent} onChange={(e) => setMessageContent(e.target.value)} />
            <div className="userticket-send-message-options">
                <div className="userticket-send-message-options-left">
                    <button className="btn btn-snb ml-0" onClick={() => navigate(`/dashboard/users/${userId}`)}><FontAwesomeIcon icon={faUser} />View User</button>
                </div>
                <div className="userticket-send-message-options-right">
                    <button className="btn btn-snb-outline mr-2" onClick={() => markReplacement()}>Mark Ticket Replacement</button>
                    <button className="btn btn-snb-outline mr-2" onClick={() => markSolved()}>Mark Ticket Solved</button>
                    <button className="btn btn-snb" onClick={() => sendTicketMessage()}>Send Message</button>
                </div>
            </div>
        </div>
    )
};

const Ticket = () => {
    const [ticketClosed, setTicketClosed] = useState(false);
    const [messages, setMessages] = useState([]);
    const [messageContent, setMessageContent] = useState(null);
    const [orderData, setOrderData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [userId, setUserId] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    const [orderToLink, setOrderToLink] = useState(null);

    const [productToReplace, setProductToReplace] = useState(null);
    const [quantityToReplace, setQuantityToReplace] = useState(1);

    const [balanceToAdd, setBalanceToAdd] = useState(1);

    const navigate = useNavigate();

    const getTicketId = () => {
        let tId = window.location.href.split("/tickets/")[1];
        return tId.includes("/") ? tId.split("/")[0] : tId;
    };

    const fetchData = async () => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if (!data.success) {
                if (request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setMessages(data.ticket.messages);
            setTicketClosed(data.ticket.closed);
            if (data.ticket.order !== null) {
                setOrderData(data.ticket.order);
                if (data.ticket.order.products_replacement_available && data.ticket.order.products_replacement_available.length > 0) {
                    setProductToReplace(data.ticket.order.products_replacement_available[0].product_id);
                }
            } else {
                setOrderData(null);
            };
            if (data.ticket.user !== null) {
                setUserData(data.ticket.user);
                if(data.ticket.user.recent_orders && data.ticket.user.recent_orders.length > 0) {
                    setOrderToLink(data.ticket.user.recent_orders[0]._id);
                };
            } else {
                setUserData(null);
            }
            setUserId(data.ticket.user_id);
            setLoaded(true);

            setTimeout(() => {
                let el = document.getElementById("userticket-messages");
                el.scrollTop = el.scrollHeight;
            }, 300);
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const sendTicketMessage = async () => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/messages`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    content: messageContent
                }),
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            setMessageContent(null);
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const markSolved = async () => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/close`, {
                method: "DELETE",
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const markReplacement = async () => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/markreplacement`, {
                method: "DELETE",
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const submitLinkOrder = async() => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/linkorder`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    order_id: orderToLink
                }),
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const submitUnlinkOrder = async() => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/unlinkorder`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const forwardTelegramMessage = async(message_id) => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/forwardmessage`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    message_id
                }),
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const submitReplacement = async () => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/replace`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    product_id: productToReplace,
                    quantity: quantityToReplace
                }),
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    const submitCreditBalance = async () => {
        setLoaded(false);
        try {
            const data = await fetch(`${API_URL}/admin/dashboard/tickets/${getTicketId()}/issuecredit`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    amount: balanceToAdd
                }),
                credentials: "include"
            }).then(r => r.json());
            if (!data.success) {
                setError(data.response);
                setLoaded(true);
                return;
            };
            fetchData();
        } catch (e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (error !== null) return <DashboardError error={error} />;
    if (loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Ticket {getTicketId()}</div>
                    <div className="user-home-welcome-text">View, manage and search tickets</div>
                </div>
                <div className="userticket-content">
                {orderData !== null
                        ?
                        <div className="userticket-order-actions">
                            <div className="userticket-order-data">
                                <div className="txt-main-title"><FontAwesomeIcon icon={faShoppingCart} /> This ticket relates to the following order</div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Order ID</div>
                                    <div className="txt-title-right">{orderData.order_id}</div>
                                </div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Order Title</div>
                                    <div className="txt-title-right">{orderData.title}</div>
                                </div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Order Status</div>
                                    <div className="txt-title-right">{orderData.status}</div>
                                </div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Order Total</div>
                                    <div className="txt-title-right">${orderData.total.toFixed(2)}</div>
                                </div>
                                <button className="btn btn-snb" onClick={() => navigate(`/dashboard/orders/${orderData.order_id}`)}>View</button>
                                <button className="btn btn-snb mt-2" onClick={() => submitUnlinkOrder()}>Unlink Order</button>
                            </div>
                            <div className="userticket-order-action-comp">
                                <div className="txt-main-title"><FontAwesomeIcon icon={faScrewdriverWrench} /> Replace order product</div>

                                <div className="input-group-split">
                                    <div className="input-group">
                                        <label>Quantity</label>
                                        <input className="input" type="number" defaultValue={quantityToReplace} onChange={(e) => setQuantityToReplace(e.target.value)} />
                                    </div>
                                    <div className="input-group">
                                        <label>Product to replace</label>
                                        <select className="input input-select" defaultValue={productToReplace} onChange={(e) => {
                                            setProductToReplace(e.target.value);
                                            setQuantityToReplace(1);
                                        }}>
                                            {orderData.products_replacement_available.map((product) => (
                                                <option value={product.product_id}>{product.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <button className="btn btn-snb" onClick={() => submitReplacement()}>Issue replacement</button>
                            </div>
                            <div className="userticket-order-action-comp">
                                <div className="txt-main-title"><FontAwesomeIcon icon={faWallet} /> Issue user credit balance</div>

                                <div className="input-group">
                                    <label>Amount to add</label>
                                    <input className="input" type="number" max={orderData.total} defaultValue={balanceToAdd} onChange={(e) => {
                                        e.target.value = parseFloat(e.target.value);
                                        if (e.target.value > orderData.total) return setBalanceToAdd(orderData.total);
                                        if (e.target.value < 0) return;
                                        setBalanceToAdd(e.target.value);
                                    }} />
                                </div>

                                <button className="btn btn-snb" onClick={() => submitCreditBalance()}>Issue credit balance</button>
                            </div>
                        </div>
                        : null}
                    {userData && orderData === null
                        ?
                        <div className="userticket-order-actions">
                            <div className="userticket-order-data">
                                <div className="txt-main-title"><FontAwesomeIcon icon={faUser} /> This ticket relates to the following user</div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Username</div>
                                    <div className="txt-title-right">{userData.username}</div>
                                </div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Email</div>
                                    <div className="txt-title-right">{userData.email}</div>
                                </div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Orders</div>
                                    <div className="txt-title-right">{userData.total_orders}</div>
                                </div>
                                <div className="txt-title">
                                    <div className="txt-title-left">Total Spend</div>
                                    <div className="txt-title-right">${userData.total_spend.toFixed(2)}</div>
                                </div>
                                <button className="btn btn-snb" onClick={() => navigate(`/dashboard/users/${userData.user_id}`)}>View</button>
                            </div>
                            <div className="userticket-order-action-comp">
                                <div className="txt-main-title"><FontAwesomeIcon icon={faScrewdriverWrench} /> Link ticket to order</div>

                                <div className="input-group">
                                    <label>Order to link</label>
                                    <select className="input input-select" defaultValue={productToReplace} onChange={(e) => {
                                        setOrderToLink(e.target.value)
                                    }}>
                                        {userData.recent_orders.map((order) => (
                                            <option value={order._id}>{order.title} - ${order.total_order_value} ({new Date(order.created_at).toLocaleString()})</option>
                                        ))}
                                    </select>
                                </div>

                                <button className="btn btn-snb" onClick={() => submitLinkOrder()}>Link order to ticket</button>
                            </div>
                        </div>
                        : null}
                </div>
                <div className="userticket-messages" id="userticket-messages">
                    {messages.map((message, index) => {
                        return (
                            <div className={`userticket-message-container ${message.from === "staff" ? "userticket-message-container-staff" : "userticket-message-container-user"}`}>
                                <div className={`userticket-message-content ${message.from === "staff" ? "userticket-message-content-staff" : "userticket-message-content-user"}`}>
                                    <div className={`userticket-message ${message.from === "staff" ? "userticket-message-staff" : "userticket-message-user"}`}>
                                        {message.photo_file_id && typeof message.photo_file_id === "string" && message.photo_file_id.startsWith("http")
                                        ? <img className="userticket-message-image" src={message.photo_file_id} />
                                        : null}
                                        {message.content.split("\n").map(s => <div className="text-line">{s}</div>)}
                                        {message.photo_message_id ? <button className="btn btn-nrm" onClick={() => forwardTelegramMessage(message.photo_message_id)}>Forward To Telegram</button> : null}
                                    </div>
                                    <div className="userticket-message-info-text">{message.from === "staff" ? "Topalts Support" : "User"} • {new Date(message.created_at).toLocaleString()}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="userticket-actions">
                    {ticketClosed === true
                        ? <div className="ticket-closed-alert"><FontAwesomeIcon icon={faExclamationCircle} />This ticket is closed</div>
                        : <TicketSendMessage messageContent={messageContent} setMessageContent={setMessageContent} sendTicketMessage={sendTicketMessage} markSolved={markSolved} markReplacement={markReplacement} userId={userId} />
                    }
                </div>
            </div>
        </div>
    )
};

export default Ticket;