import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const EditProduct = ({ category_id, product_id, setRefresh, productName, setProductName, productPrice, setProductPrice, productDeliveryType, setProductDeliveryType, productMinQuantity, setProductMinQuantity, productVisible, setProductVisible }) => {
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/products/${product_id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: productName,
                    price: productPrice,
                    delivery_type: productDeliveryType,
                    min_quantity: productMinQuantity,
                    visible: productVisible
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setProductName("");
            setProductPrice(0);
            setProductDeliveryType("automatic");
            setProductMinQuantity(0);
            setRefresh(Math.random());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    const submitDelete = async() => {
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/products/${product_id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json"
                },
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
            };
            setLoaded(true);
            navigate(`/dashboard/products/categories/${category_id}`);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        };
    };

    if(loaded === false) return (
        <div className="item-create-box mt-2">
            <DashboardLoading fullPage={false} />
        </div>
    );

    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Edit product</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Name</label>
                <input type="text" className="input" placeholder="My Product Name" defaultValue={productName} onChange={(e) => setProductName(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Price</label>
                <input type="text" className="input" min={0} defaultValue={productPrice} onChange={(e) => setProductPrice(parseFloat(e.target.value).toFixed(2))} />
            </div>

            <div className="input-group">
                <label>Delivery Type</label>
                <select className="input" defaultValue={productDeliveryType} onChange={(e) => setProductDeliveryType(e.target.value)}>
                    <option value="automatic">Automatic</option>
                    <option value="manual">Manual</option>
                </select>
            </div>

            <div className="input-group">
                <label>Minimum Quantity</label>
                <input type="number" className="input" placeholder="1" min={1} defaultValue={productMinQuantity} onChange={(e) => setProductMinQuantity(parseInt(e.target.value))} />
            </div>

            <div className="input-group input-group-checkbox">
                <label>Visible</label>
                <input type="checkbox" defaultChecked={productVisible} onChange={(e) => setProductVisible(e.target.checked)} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Edit Product</button>
            <button className="btn btn-dng" onClick={() => submitDelete()}>Delete Product</button>
        </div>
    )
};

const ProductStockEdit = ({ product_id, setRefresh, productStock, setProductStock }) => {
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/products/${product_id}/stock`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    stock: productStock.split("\n")
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setRefresh(Math.random());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box mt-2">
            <DashboardLoading fullPage={false} />
        </div>
    );

    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Edit product stock</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <textarea rows={8} className="input mt-1" defaultValue={productStock} onChange={(e) => setProductStock(e.target.value)} />
            <div className="item-box-lg-bottom">
                <button className="btn btn-snb" onClick={() => submit()}>Save</button>
            </div>
        </div>
    )
};

const Product = () => {
    const [productName, setProductName] = useState("");
    const [productPrice, setProductPrice] = useState(1);
    const [productDeliveryType, setProductDeliveryType] = useState("automatic");
    const [productMinQuantity, setProductMinQuantity] = useState(1);
    const [productVisible, setProductVisible] = useState(true);
    const [productStock, setProductStock] = useState("");
    const [canEditProduct, setCanEditProduct] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const getCategoryId = () => {
        try {
            let cId = window.location.pathname.split("/")[4];
            if(cId.includes("/")) return cId.split("/")[0];
            return cId;
        } catch(e) {
            console.log(e);
            return null;
        }
    };

    const getProductId = () => {
        try {
            let cId = window.location.pathname.split("/")[5];
            if(cId.includes("/")) return cId.split("/")[0];
            return cId;
        } catch(e) {
            console.log(e);
            return null;
        }
    };

    const fetchData = async() => {
        setLoaded(false);
        try {
            const getProductData = async() => {
                const request = await fetch(`${API_URL}/admin/dashboard/products/${getProductId()}`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await request.json();
                if(!data.success) {
                    if(request.status === 403) return navigate("/auth/login");
                    setError(data.response);
                    return;
                };
                setProductName(data.product.name);
                setProductPrice(data.product.price);
                setProductDeliveryType(data.product.delivery_type);
                setProductVisible(data.product.visible);
                setProductMinQuantity(data.product.min_quantity);
                setCanEditProduct(data.can_edit_product);
            };
            const getProductStock = async() => {
                const request = await fetch(`${API_URL}/admin/dashboard/products/${getProductId()}/stock`, {
                    method: "GET",
                    credentials: "include"
                });
                const data = await request.json();
                if(!data.success) {
                    if(request.status === 403) return navigate("/auth/login");
                    setError(data.response);
                    return;
                };
                setProductStock(data.product_stock);
            };
            await getProductData();
            await getProductStock();
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">{productName}</div>
                    <div className="user-home-welcome-text">View and manage {productName} and it's stock</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <ProductStockEdit product_id={getProductId()} setRefresh={setRefresh} productStock={productStock} setProductStock={setProductStock} />
                    </div>
                    <div className="dashboard-page-split-right">
                        {canEditProduct ? <EditProduct
                            category_id={getCategoryId()}
                            product_id={getProductId()}
                            setRefresh={() => setRefresh()}
                            productName={productName}
                            setProductName={setProductName}
                            productPrice={productPrice}
                            setProductPrice={setProductPrice}
                            productDeliveryType={productDeliveryType}
                            setProductDeliveryType={setProductDeliveryType}
                            productMinQuantity={productMinQuantity}
                            setProductMinQuantity={setProductMinQuantity}
                            productVisible={productVisible}
                            setProductVisible={setProductVisible}
                        /> : null}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Product;