import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const ProductCategoriesTable = ({ product_categories }) => {
    const navigate = useNavigate();

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Products</th>
                    <th>Visible</th>
                    <th>Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {product_categories.map((product_category, index) => (
                    <tr>
                        <td>{product_category.name}</td>
                        <td>{product_category.products}</td>
                        <td className="capitalize">{product_category.visible.toString()}</td>
                        <td>{new Date(product_category.created_at).toLocaleString()}</td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/products/categories/${product_category.category_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const CreateProductCategory = ({ setRefresh }) => {
    const [categoryName, setCategoryName] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");
    const [categoryImageURL, setCategoryImageURL] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/productcategories`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: categoryName,
                    description: categoryDescription,
                    image_url: categoryImageURL
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setCategoryName("");
            setCategoryDescription("");
            setCategoryImageURL("");
            setRefresh(Math.random().toString());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Create product category</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Name</label>
                <input type="text" className="input" placeholder="My Category Name" defaultValue={categoryName} onChange={(e) => setCategoryName(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Description</label>
                <textarea rows={4} className="input" placeholder={"Sunt exercitation in aliqua velit deserunt ea. Reprehenderit occaecat id fugiat quis incididunt officia eiusmod do ipsum fugiat duis sit id eu."} defaultValue={categoryDescription} onChange={(e) => setCategoryDescription(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Image URL</label>
                <input type="text" className="input" placeholder="https://i.imgur.com/xyz.png" defaultValue={categoryImageURL} onChange={(e) => setCategoryImageURL(e.target.value)} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Create Product Category</button>
        </div>
    )
};

const ProductCategories = () => {
    const [productCategories, setProductCategories] = useState([]);
    const [canCreateCategory, setCanCreateCategory] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/productcategories`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setProductCategories(data.product_categories);
            setCanCreateCategory(data.can_create_category);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Product categories</div>
                    <div className="user-home-welcome-text">Create, view and manage product categories</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <ProductCategoriesTable product_categories={productCategories} />
                    </div>
                    <div className="dashboard-page-split-right">
                        {canCreateCategory ? <CreateProductCategory setRefresh={() => setRefresh()} /> : null}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProductCategories;