import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const AdminUsersTable = ({ admin_users }) => {
    const navigate = useNavigate();

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>User Type</th>
                    <th>Last Login At</th>
                    <th>Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {admin_users.map((admin_user, index) => (
                    <tr>
                        <td>{admin_user.username}</td>
                        <td>{admin_user.user_type}</td>
                        <td>{new Date(admin_user.last_login_at).toLocaleString()}</td>
                        <td>{new Date(admin_user.created_at).toLocaleString()}</td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/adminusers/${admin_user.user_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const CreateAdminUsers = ({ setRefresh }) => {
    const [adminUsername, setAdminUsername] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminUsertype, setAdminUsertype] = useState("support");
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/adminusers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    username: adminUsername,
                    password: adminPassword,
                    user_type: adminUsertype
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setAdminUsername("");
            setAdminPassword("");
            setAdminUsertype("");
            setRefresh(Math.random().toString());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Create admin user</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Username</label>
                <input type="text" className="input" placeholder="myadminuser" defaultValue={adminUsername} onChange={(e) => setAdminUsername(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Password</label>
                <input type="password" className="input" placeholder="********" defaultValue={adminPassword} onChange={(e) => setAdminPassword(e.target.value)} />
            </div>

            <div className="input-group">
                <label>User Type</label>
                <select className="input" defaultValue={adminUsertype} onChange={(e) => setAdminUsertype(e.target.value)}>
                    <option value="support">Support</option>
                    <option value="stocker">Stocker</option>
                    <option value="supportstocker">Support & Stocker</option>
                    <option value="superuser">Superuser</option>
                </select>
            </div>

            {adminUsertype === "superuser" ? <div className="auth-form-error"><b>Warning!</b> Superusers have all privileges and can complete all actions on the admin dashboard, including deleting other superusers. Only create a superuser account for someone you trust.</div> : null}
            {["stocker", "supportstocker"].includes(adminUsertype) ? <div className="form-info">The user will only have permission to view and edit the stock of products you select, you can select products that they can edit later</div> : null}

            <button className="btn btn-snb" onClick={() => submit()}>Create admin user</button>
        </div>
    )
};

const AdminUsers = () => {
    const [adminUsers, setAdminUsers] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/adminusers`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setAdminUsers(data.admin_users);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Admin users</div>
                    <div className="user-home-welcome-text">Create, view and manage admin users</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <AdminUsersTable admin_users={adminUsers} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <CreateAdminUsers setRefresh={() => setRefresh()} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AdminUsers;