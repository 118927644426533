import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const OrdersTable = ({ orders, ordersTotal, setSkip }) => {
    const navigate = useNavigate();

    return (
        <>
            <table className="userorders-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {orders.map((order, index) => (
                        <tr>
                            <td>
                                <div className="dplfx">
                                    {order.title} {order.stock_delivery_issue ? <div className="userorders-table-status userorders-table-status-awaiting_payment">Requires Manual Delivery</div> : null}
                                </div>
                            </td>
                            <td>${order.total.toFixed(2)}</td>
                            <td><div className={`userorders-table-status userorders-table-status-${order.status.toLowerCase()}`}>{order.status.replaceAll("_", " ")}</div></td>
                            <td>{new Date(order.created_at).toLocaleString()}</td>
                            <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/orders/${order.order_id}`)}>View</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="table-pagnation">
                <div className="table-pagnation-text">
                    <div><b>Showing 36 records per page</b></div>
                    <div>Total records {ordersTotal}</div>
                    <div>Total pages {Math.ceil(ordersTotal / 36)}</div>
                </div>
                <div className="table-pagnation-buttons">
                    {Array(Math.ceil(ordersTotal / 36)).fill(1).map((page, index) => {
                        return <button className="btn btn-pag" onClick={() => setSkip(index * 36)}>{index + 1}</button>
                    })}
                </div>
            </div>
        </>
    );
};

const OrderSearch = ({ setOrders }) => {
    const [searchType, setSearchType] = useState("username");
    const [searchValue, setSearchValue] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/orders/search`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    search_type: searchType,
                    search_value: searchValue
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setOrders(data.orders);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Search orders</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Search Type</label>
                <select className="input" defaultValue={searchType} onChange={(e) => setSearchType(e.target.value)}>
                    <option value="username">Search by Username</option>
                    <option value="email">Search by Email</option>
                    <option value="order_id">Search by Order ID</option>
                    <option value="user_id">Search by User ID</option>
                </select>
            </div>

            <div className="input-group">
                <label>Search Value</label>
                <input type="text" className="input" defaultValue={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Search</button>
        </div>
    )
};

const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [ordersTotal, setOrdersTotal] = useState(0);
    const [skip, setSkip] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/orders`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    skip: skip
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setOrders(data.orders);
            setOrdersTotal(data.order_count);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [skip]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Orders</div>
                    <div className="user-home-welcome-text">View, manage and search orders</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <OrdersTable setSkip={setSkip} orders={orders} ordersTotal={ordersTotal} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <OrderSearch setOrders={setOrders} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Orders;