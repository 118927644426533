import { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import Cookies from "js-cookie";
import UserContext from "../../../contexts/user";
import { API_URL } from "../../../config";

const Logout = () => {
    const navigate = useNavigate();
    const { setUser } = useContext(UserContext);

    useEffect(() => {
        const logout = async() => {
            try {
                await fetch(`${API_URL}/admin/auth/logout`, { credentials: "include" });
            } catch(e) {
                console.log(e);
            };
    
            setUser({ loggedin: false, username: null });
            Cookies.remove("admin_loggedin");
            Cookies.remove("admin_username");
            navigate("/auth/login");
        };
        logout();
    }, []);

    return (
        <div className="logout">
            <span>Logging out...</span>
        </div>
    );
};

export default Logout;