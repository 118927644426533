import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faWallet, faBoxesStacked, faBars, faEnvelope, faUsers, faTags, faRightFromBracket, faUserGear, faBullhorn, faChartLine } from "@fortawesome/free-solid-svg-icons";

import UserContext from "../../../contexts/user";

import { LOGO_URL } from "../../../config";

const SidebarOption = ({ icon, name, to }) => {
    return (
        <NavLink className={({ isActive }) => `admin-sidebar-option ${isActive ? "admin-sidebar-option-active" : null}`} to={to}>
            <FontAwesomeIcon icon={icon} />
            <div className="admin-sidebar-option-text">{name}</div>
        </NavLink>
    )
};

const Sidebar = () => {
    const { user } = useContext(UserContext);

    let sidebarOptions = <></>;
    if(user.user_type === "support") {
        sidebarOptions = <>
            <SidebarOption icon={faEnvelope} name="Tickets" to="/dashboard/tickets" />
        </>
    } else if(user.user_type === "stocker") {
        sidebarOptions = <>
            <SidebarOption icon={faBoxesStacked} name="Products" to="/dashboard/products/categories" />
        </>
    } else if(user.user_type === "supportstocker") {
        sidebarOptions = <>
            <SidebarOption icon={faBoxesStacked} name="Products" to="/dashboard/products/categories" />
            <SidebarOption icon={faEnvelope} name="Tickets" to="/dashboard/tickets" />
        </>
    } else if(user.user_type === "superuser") {
        sidebarOptions = <>
            <SidebarOption icon={faHouse} name="Home" to="/dashboard/home" />
            <SidebarOption icon={faBoxesStacked} name="Products" to="/dashboard/products/categories" />
            <SidebarOption icon={faTags} name="Tags" to="/dashboard/tags" />
            <SidebarOption icon={faBars} name="Orders" to="/dashboard/orders" />
            <SidebarOption icon={faBullhorn} name="Announcements" to="/dashboard/announcements" />
            <SidebarOption icon={faEnvelope} name="Tickets" to="/dashboard/tickets" />
            <SidebarOption icon={faUsers} name="Users" to="/dashboard/users" />
            <SidebarOption icon={faWallet} name="Payment Methods" to="/dashboard/paymentmethods" />
            <SidebarOption icon={faUserGear} name="Admin Users" to="/dashboard/adminusers" />
        </>
    }

    return (
        <div className="admin-dashboard-sidebar">
            <div className="admin-dashboard-sidebar-branding">
                <img className="admin-dashboard-sidebar-branding-img" src={LOGO_URL} />
            </div>
            <div className="admin-dashboard-sidebar-options">
                {sidebarOptions}
                <SidebarOption icon={faRightFromBracket} name="Logout" to="/auth/logout" />
            </div>
        </div>
    );
};

export default Sidebar;