import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../../config";

import DashboardLoading from "../partials/loading";
import DashboardError from "../partials/error";

const TagsTable = ({ tags }) => {
    const navigate = useNavigate();

    return (
        <table className="userorders-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Color</th>
                    <th>Created At</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                {tags.map((tag, index) => (
                    <tr>
                        <td>{tag.name}</td>
                        <td><div style={{ background: tag.color_hex, padding: "12px", borderRadius: "6px" }} /></td>
                        <td>{new Date(tag.created_at).toLocaleString()}</td>
                        <td><button className="btn btn-nrm btn-nrm-sm" onClick={() => navigate(`/dashboard/tags/${tag.tag_id}`)}>View</button></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const CreateTag = ({ setRefresh }) => {
    const [tagName, setTagName] = useState("");
    const [tagColor, setTagColor] = useState("");
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const navigate = useNavigate();

    const submit = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/tags`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    name: tagName,
                    color_hex: tagColor
                }),
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setTagName("");
            setTagColor("");
            setRefresh(Math.random().toString());
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later");
            setLoaded(true);
        }
    };

    if(loaded === false) return (
        <div className="item-create-box">
            <DashboardLoading fullPage={false} />
        </div>
    );
    
    return (
        <div className="item-create-box">
            <div className="item-title-margin-small">
                <div className="user-home-welcome-title">Create tag</div>
            </div>

            {error !== false ? <div className="auth-form-error">{error}</div> : null}

            <div className="input-group">
                <label>Name</label>
                <input type="text" className="input" placeholder="My Tag Name" defaultValue={tagName} onChange={(e) => setTagName(e.target.value)} />
            </div>

            <div className="input-group">
                <label>Color</label>
                <input type="color" className="input" style={{ height: "60px" }} defaultValue={tagColor} onChange={(e) => setTagColor(e.target.value)} />
            </div>

            <button className="btn btn-snb" onClick={() => submit()}>Create tag</button>
        </div>
    )
};

const Tags = () => {
    const [tags, setTags] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [refresh, setRefresh] = useState(null);
    const navigate = useNavigate();

    const fetchData = async() => {
        setLoaded(false);
        try {
            const request = await fetch(`${API_URL}/admin/dashboard/tags`, {
                method: "GET",
                credentials: "include"
            });
            const data = await request.json();
            if(!data.success) {
                if(request.status === 403) return navigate("/auth/login");
                setError(data.response);
                setLoaded(true);
                return;
            };
            setTags(data.tags);
            setLoaded(true);
        } catch(e) {
            console.log(e);
            setError("An unknown error occurred, please try again later!");
            setLoaded(true);
        };
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        fetchData();
    }, [refresh]);

    if(error !== null) return <DashboardError error={error} />;
    if(loaded === false) return <DashboardLoading />;

    return (
        <div className="dashboard-page dashboard-page-split">
            <div className="dashboard-page-content">
                <div className="item-title-margin">
                    <div className="user-home-welcome-title">Tags</div>
                    <div className="user-home-welcome-text">Create, view and manage product category tags</div>
                </div>
                <div className="dashboard-page-split-content">
                    <div className="dashboard-page-split-left">
                        <TagsTable tags={tags} />
                    </div>
                    <div className="dashboard-page-split-right">
                        <CreateTag setRefresh={() => setRefresh()} />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Tags;